import React, { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { createMarkup } from '@agria/utils/src/createMarkup';
import { Container } from '../../container/Container';
import { useBackgroundColor } from '../../../utils';
import { Typography } from '../../typography/Typography';
import { LogoInverse, LogoIE } from '../../svg';
import { Stack } from '../../stack/Stack';
import { Divider } from '../../divider/Divider';
import { LinkTo } from '../../linkTo/LinkTo';
import { SslSecure } from '../../svg/svgs/SslSecure';
import { Facebook, Twitter, Youtube, Instagram, LinkedIn } from '../../icon';
import tokens from './tokens';
import { CookiePreferenceButton } from '../../cookiePreferenceButton/CookiePreferenceButton';
import { ThemeColorsKeys } from '../../../styles/tokens/colors';

// Interface

export interface FooterProps {
  brandMessage?: string;
  smallText?: string;
  hideNavigation?: boolean;
  navigationColumns?: FooterMenuProps[];
  socialLinks?: {
    facebook?: string;
    linkedIn?: string;
    twitter?: string;
    youTube?: string;
    instagram?: string;
    pinterest?: string;
  };
  secondaryNavigation?: {
    label?: string;
    target?: string;
    url?: string;
  }[];
}

export interface FooterMenuProps {
  header?: string;
  links?: {
    label?: string;
    url?: string;
    target?: string;
  }[];
}

// Styles

const StyledLinkBar = styled.div`
  ${({ theme: { colors, space } }) => css`
    ${useBackgroundColor('brandTwo100')};
    padding: ${space.fluidLarge} 0;

    a,
    button {
      text-decoration: none;
      color: ${colors[(tokens.linkColour as ThemeColorsKeys) || 'brandOne']};

      &:hover {
        color: ${colors.utilityWhite};
        text-decoration: ${tokens.linkHoverDecoration || 'none'};
      }
    }
  `};
`;

const StyledFooter = styled.footer<StyledFooterProps>`
  ${({ theme: { colors, space }, hasLinkBar }) => css`
    ${useBackgroundColor(tokens.backgroundColour as ThemeColorsKeys)};
    padding: ${hasLinkBar ? space.fluidLarge : space.fluidXxLarge} 0;

    a,
    button {
      text-decoration: none;
      color: ${colors[(tokens.linkColour as ThemeColorsKeys) || 'brandOne']};

      &:hover {
        color: ${colors.utilityWhite};
        text-decoration: ${tokens.linkHoverDecoration || 'none'};
      }
    }
  `};
`;

const StyledBrandBar = styled.div`
  ${({ theme: { space, media } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xSmall};

    @media (min-width: ${media.medium}) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  `};
`;

const StyledLogos = styled.div`
  ${({ theme: { space, media } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.fluidxxSmallToSmall};
    align-items: flex-start;
  `};
`;

const StyledSocialLinks = styled.ul`
  ${({ theme: { space, colors } }) => css`
    display: flex;
    gap: ${space.xxSmall};

    a {
      display: inline-block;

      &:focus-visible {
        outline: 4px solid ${colors.systemFocus};
      }
    }
  `};
`;

const StyledSmallLinks = styled.ul`
  ${({ theme: { space, media, colors } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xxSmall};

    @media (min-width: ${media.large}) {
      flex-direction: row;
      gap: ${space.small};

      li + li {
        border-left: solid 1px
          ${colors[tokens.linkDividerColor as ThemeColorsKeys] ||
          colors.brandOne80};
        padding-left: ${space.small};
      }
    }
  `};
`;

const StyledDiv = styled.div`
  ${({ theme: { space, media } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: ${space.medium};

    @media (min-width: ${media.large}) {
      flex-direction: row;
    }
  `};
`;

const StyledMenus = styled.div`
  ${({ theme: { space, media } }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${space.fluidxxSmallToSmall};

    @media (min-width: ${media.large}) {
      flex-direction: row;
      justify-content: space-between;
      width: 50%;
    }
  `};
`;

const StyledMenu = styled.div`
  ${({ theme: { space, media, maxMedia, colors } }) => css``};
`;

const StyledIcon = styled.div`
  svg,
  img {
    width: 48px;
    height: 48px;
  }
`;

const StyledSslSecureContainer = styled.div`
  ${({ theme: { media } }) => css`
    width: 125px;

    @media (min-width: ${media.small}) {
      width: 167px;
    }
  `};
`;

// JSX

export const FooterMenu = ({ header, links }: FooterMenuProps) => (
  <StyledMenu role="presentation">
    <Stack role="presentation" gap="fluidxxSmallToSmall">
      {header && (
        <Typography
          component="h2"
          variant="headingSmall"
          role="separator"
          aria-label={header}
        >
          {header}
        </Typography>
      )}
      {links?.map(({ label, url }, index) =>
        url ? (
          <StyledDiv key={index} role="listitem">
            <LinkTo to={url}>{label}</LinkTo>
          </StyledDiv>
        ) : null
      )}
    </Stack>
  </StyledMenu>
);

export const Footer: FC<FooterProps> = ({
  brandMessage,
  navigationColumns,
  secondaryNavigation,
  smallText,
  socialLinks,
  hideNavigation,
}) => (
  <>
    {tokens.seperateLinkBar && secondaryNavigation && (
      <StyledLinkBar as="nav" aria-label="Policies and Cookies Navigation Menu">
        <Container>
          <StyledSmallLinks>
            {secondaryNavigation?.map((nav, index) =>
              nav.url ? (
                <Typography component="li" variant="bodySmall" key={index}>
                  <LinkTo to={nav.url}>{nav.label}</LinkTo>
                </Typography>
              ) : null
            )}
            <Typography component="li" variant="bodySmall" key="onetrust">
              <CookiePreferenceButton
                buttonLabel="Manage your cookies"
                noButtonPadding
              />
            </Typography>
          </StyledSmallLinks>
        </Container>
      </StyledLinkBar>
    )}
    <StyledFooter hasLinkBar={tokens.seperateLinkBar}>
      <Container>
        <Stack gap="fluidMedium">
          <StyledBrandBar>
            {tokens.includeInverseLogo && (
              <LogoInverse aria-hidden="true" aria-hidden="true" />
            )}

            {brandMessage && (
              <Typography variant="bodyLarge">{brandMessage}</Typography>
            )}
          </StyledBrandBar>
          {!hideNavigation && (
            <>
              {socialLinks?.facebook ||
              socialLinks?.twitter ||
              socialLinks?.linkedIn ||
              socialLinks?.instagram ||
              socialLinks?.youTube ||
              (navigationColumns && navigationColumns?.length > 0) ? (
                <>
                  <Divider inverse />
                  <StyledDiv as="nav" aria-label="Footer Navigation Menu">
                    {navigationColumns && (
                      <StyledMenus role="list">
                        {navigationColumns?.map((navColumn, index) => (
                          <FooterMenu
                            key={index}
                            header={navColumn?.header}
                            links={navColumn?.links}
                          />
                        ))}
                      </StyledMenus>
                    )}

                    <StyledLogos>
                      <Typography component="h3" variant="bodyMediumBold">
                        Follow us
                      </Typography>
                      <StyledSocialLinks>
                        {socialLinks?.facebook && (
                          <li>
                            <a
                              href={socialLinks?.facebook}
                              target="_blank"
                              rel="noreferrer"
                              aria-label="Facebook"
                            >
                              <StyledIcon>
                                <Facebook />
                              </StyledIcon>
                            </a>
                          </li>
                        )}

                        {socialLinks?.twitter && (
                          <li>
                            <a
                              href={socialLinks?.twitter}
                              target="_blank"
                              rel="noreferrer"
                              aria-label="Twitter"
                            >
                              <StyledIcon>
                                <Twitter />
                              </StyledIcon>
                            </a>
                          </li>
                        )}
                        {socialLinks?.linkedIn && (
                          <li>
                            <a
                              href={socialLinks?.linkedIn}
                              target="_blank"
                              rel="noreferrer"
                              aria-label="LinkedIn"
                            >
                              <StyledIcon>
                                <LinkedIn />
                              </StyledIcon>
                            </a>
                          </li>
                        )}
                        {socialLinks?.instagram && (
                          <li>
                            <a
                              href={socialLinks?.instagram}
                              target="_blank"
                              rel="noreferrer"
                              aria-label="Instagram"
                            >
                              <StyledIcon>
                                <Instagram />
                              </StyledIcon>
                            </a>
                          </li>
                        )}
                      </StyledSocialLinks>
                    </StyledLogos>
                  </StyledDiv>
                  <Divider inverse />
                </>
              ) : null}
              {secondaryNavigation && !tokens.seperateLinkBar && (
                <StyledDiv
                  as="nav"
                  aria-label="Policies and Cookies Navigation Menu"
                >
                  <StyledSmallLinks as="ul">
                    {secondaryNavigation?.map((nav, index) =>
                      nav.url ? (
                        <Typography
                          component="li"
                          variant="bodySmall"
                          key={index}
                        >
                          <LinkTo to={nav.url}>{nav.label}</LinkTo>
                        </Typography>
                      ) : null
                    )}
                    <Typography
                      component="li"
                      variant="bodySmall"
                      key="onetrust"
                    >
                      <CookiePreferenceButton
                        buttonLabel="Manage your cookies"
                        noButtonPadding
                      />
                    </Typography>
                  </StyledSmallLinks>
                </StyledDiv>
              )}
            </>
          )}
          {smallText && (
            <Typography
              variant="bodySmall"
              component="div"
              color={(tokens.smallTextColor as ThemeColorsKeys) || 'brandOne20'}
              suppressHydrationWarning
            >
              {createMarkup(smallText)}
            </Typography>
          )}
          <StyledSslSecureContainer>
            <SslSecure title="SSL Secure logo" />
          </StyledSslSecureContainer>
        </Stack>
      </Container>
    </StyledFooter>
  </>
);

export default Footer;
